import { config } from '@config';
import { Configuration as ExquanceInsightsConfiguration } from '@xq/exquance-insights-gateway-frontend-client';
import {
  AuthV2Api,
  Configuration as IamConfiguration,
  ConfigurationParameters
} from '@xq/iam-frontend-client';
import packageJson from '../../package.json';

export interface AppVersion {
  version: string;
  name: string;
}

export const appVersion: AppVersion = {
  version: packageJson.version,
  name: packageJson.name
};

const getConfiguration = (basePath: string): ConfigurationParameters => {
  return {
    basePath: basePath,
    credentials: 'include',
    headers: {
      'xq-app-version': appVersion.version,
      'xq-app-name': appVersion.name
    }
  };
};

export const iamGateway = new AuthV2Api(
  new IamConfiguration(getConfiguration(config.apiUrl))
);

/** Usage example:
 *** const adminUsersGateway = new LayoutApi(adminApiConfiguration)
 **/

export const exquanceInsightsApiConfiguration =
  new ExquanceInsightsConfiguration(
    getConfiguration(`${config.apiUrl}/exquance-insights`)
  );
