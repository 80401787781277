import { config } from '@config';
import {
  exquanceInsightsApiConfiguration,
  generateHeaderAppItems,
  handleBackendError,
  iamGateway,
  wait
} from '@services';
import {
  LayoutApi,
  LayoutDTOResponse
} from '@xq/exquance-insights-gateway-frontend-client';
import { HeaderAppNames } from '@xq/ui-kit';
import { LayoutData } from './dataTypes';

export interface LayoutService {
  fetchData(): Promise<LayoutData>;

  authCheck(): Promise<void>;

  logout(): Promise<void>;
}

const insightsLayoutGateway = new LayoutApi(exquanceInsightsApiConfiguration);

export class LayoutService implements LayoutService {
  async fetchData(): Promise<LayoutData> {
    try {
      const response: LayoutDTOResponse =
        await insightsLayoutGateway.layoutControllerGetLayout();
      return {
        firstName: response?.firstName || '',
        lastName: response?.lastName || '',
        email: response?.email || '',
        avatar: response?.avatarUrl,
        languageIso2: response?.languageIso2,
        organizations: response?.organizations,
        licenseProducts: response?.licenseProducts,
        currentOrganizationId: response?.currentOrganizationUuid,
        apps: generateHeaderAppItems(
          response?.isOrganizationAdmin,
          response?.isXQAdmin
        )
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      await iamGateway.authV2ControllerCheck();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      await iamGateway.authV2ControllerLogout();
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}

const mockResponse: LayoutData = {
  firstName: 'Simona',
  lastName: 'Mulkova',
  licenseProducts: [],
  organizations: [
    {
      name: 'Exquance',
      uuid: '1'
    }
  ],
  currentOrganizationId: '1',
  email: 'simona.mulkova@exquance.com',
  avatar: '',
  apps: [
    {
      name: HeaderAppNames.Home,
      url: config.homeFrontendUrl
    }
  ],
  languageIso2: 'ru'
};

export class LayoutServiceMock implements LayoutService {
  async fetchData(): Promise<LayoutData> {
    try {
      await wait(100);
      return mockResponse;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      await wait(100);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      await wait(100);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}
